import React, { ComponentType } from "react"

export function withCustomBullets<T>(
    Component: ComponentType<T>
): ComponentType<T> {
    return (props: T) => (
        <Component
            {...props}
            style={{
                ...((props as any).style || {}),
            }}
            className={`${(props as any).className || ""}`}
        >
            <>
                {React.Children.map((props as any).children, (child) => {
                    if (React.isValidElement(child) && child.type === "ul") {
                        // Target <ul> elements
                        return React.cloneElement(child, {
                            style: { paddingLeft: "20px", listStyle: "none" },
                            children: React.Children.map(
                                child.props.children,
                                (liChild) => {
                                    if (
                                        React.isValidElement(liChild) &&
                                        liChild.type === "li"
                                    ) {
                                        // Target <li> elements
                                        return React.cloneElement(liChild, {
                                            style: {
                                                position: "relative",
                                                paddingLeft: "20px",
                                            },
                                            children: (
                                                <>
                                                    <span
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            left: 0,
                                                            color: "#000",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        →
                                                    </span>
                                                    {liChild.props.children}
                                                </>
                                            ),
                                        })
                                    }
                                    return liChild
                                }
                            ),
                        })
                    }
                    return child
                })}
            </>
        </Component>
    )
}
